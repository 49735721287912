import { Image, Tabs, Text } from '@akin/ui-lib';
import InfoIcon from '@akin/ui-lib/svg/InfoIcon';
import { createStyles, Tooltip } from '@mantine/core';
import PageTitle from '../PageTitle';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    [theme.fn.smallerThan('xs')]: {
      height: 'calc(100dvh - 50px)',
    },
  },
  pageName: {
    backgroundColor: theme.white,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    borderBottom: '1px solid #f3f4f6',
    justifyContent: 'space-between',

    '& .title': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .mantine-Button-root': {
      minWidth: 90,
      fontSize: 12,
      height: 26,
    },
  },
  infoIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
  },
  tabs: {
    backgroundColor: theme.white,
    '& .mantine-Tabs-tab': {
      color: '#7f7f7f',
      fontWeight: 500,
      borderWidth: 3,
      marginBottom: 'unset',
    },

    '& .mantine-Tabs-tab:hover': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },

    '& .mantine-Tabs-tabsList': {
      border: 'none',
      height: 48,
      [theme.fn.smallerThan('xs')]: {
        flexWrap: 'unset',
        overflowX: 'scroll',
      },
    },
    '& .mantine-Tabs-tab[data-active], &.mantine-Tabs-tab[data-active]:hover': {
      borderColor: '#1896B5',
      borderWidth: 3,
      color: '#000',
      fontWeight: 600,
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',

    [theme.fn.largerThan('xs')]: {
      ...theme.other.customScrollbar,
    },
  },
}));

export default function MainContainer({
  pageTitle = '',
  children,
  className,
  tabList,
  activeTab,
  setActiveTab,
  description = '',
  rightSection,
}) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)}>
      <PageTitle pageName={pageTitle} />

      <div className={classes.pageName}>
        <div className="title">
          <Text size="lg" weight={600}>
            {pageTitle}
          </Text>

          {description && (
            <Tooltip multiline width={220} label={description}>
              <div className={classes.infoIcon}>
                <InfoIcon
                  width={12}
                  height={12}
                  stroke="#000"
                  strokeWidth={2}
                />
              </div>
            </Tooltip>
          )}
        </div>

        {rightSection}
      </div>

      {tabList && (
        <Tabs
          className={cx(classes.tabs, classes.stickyTabs)}
          value={activeTab}
          onTabChange={setActiveTab}
        >
          {Object.values(tabList).map((tab) => (
            <Tabs.Panel
              key={`tab_${tab.id}`}
              id={tab.id}
              title={tab.title}
              icon={tab.icon && <Image width={20} height={20} src={tab.icon} />}
            />
          ))}
        </Tabs>
      )}

      <div className={classes.content}>{children}</div>
    </div>
  );
}
